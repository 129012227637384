<template>
    <div>   
        <div class="top">
            <div class="topleft">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item :to="{ path: '/home' }">{{$t('home')}}</el-breadcrumb-item>
                    <el-breadcrumb-item>{{$t('xtgl')}}</el-breadcrumb-item>
                    <el-breadcrumb-item>{{$t('qxgl')}}</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="topright">
                <el-link type="primary" @click="addform.pid=0;showaddperm=true">+{{$t('addzcd')}}</el-link>
            </div>
        </div>
        <div class="cont" v-loading="loading">
            <div class="mylist">
                <el-table
                    :data="tableData"
                    style="width: 100%;margin-bottom: 20px;"
                    row-key="id"
                    border
                    lazy
                    :load="load"
                    :header-cell-style="{'background':'#f5f5f5','font-weight':'bold','color':'#555555','padding':'6px 0'}"
                    :default-expand-all="false"
                    :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
                    <el-table-column
                    prop="title"
                    :label="$t('cdbt')"
                    width="300">
                    </el-table-column>
                    <el-table-column
                    prop="icon"
                    :label="$t('tup')">
                        <template slot-scope="scope">
                            <i :class="scope.row.icon"></i>
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="component"
                    :label="$t('qxlj')">
                    </el-table-column>
                    <el-table-column
                    prop="permission"
                    :label="$t('qxbs')"
                    width="200">
                    </el-table-column>
                    <el-table-column
                    prop="menuSort"
                    align="center"
                    :label="$t('paix')"
                    width="200">
                    </el-table-column>
                    <el-table-column
                    prop="address"
                    width="200"
                    :label="$t('operator')">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="$t('addzjd')" placement="top" v-if="scope.row.type!=2">
                                <el-button size="small" @click="addform.pid=scope.row.id;showaddperm=true" type="primary" icon="el-icon-plus" circle plain></el-button>
                            </el-tooltip>
                            <el-button size="small"  @click="openedit(scope.row)" type="primary" icon="el-icon-edit" circle plain style="margin-right:4px;"></el-button>
                            <el-popconfirm :title="$t('isDel')" @confirm="deleteperm(scope.row)">
                            <el-button size="small" type="warning" icon="el-icon-delete-solid" circle slot="reference" plain></el-button>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>

        <pop-up :title="$t('addcd')" width="40%" :isshow="showaddperm" :haveconfirm="true" @close="getclose" @confirm="getconfirm">
            <div slot="box">
                <el-form ref="form" :model="addform" label-width="123px">
                    <div class="popitem">
                        <el-form-item :label="$t('type')">
                            <el-radio-group v-model="addform.type">
                            <el-radio :label="0" v-if="addform.pid==0">{{$t('zhucd')}}</el-radio>
                            <el-radio :label="1" v-if="addform.pid!=0">{{$t('zcd')}}</el-radio>
                            <el-radio :label="2" v-if="addform.pid!=0">{{$t('gongn')}}</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </div>
                    <div class="popitembox">
                        <div class="popitem">
                            <el-form-item :label="$t('cdmc')">
                                <el-input v-model="addform.title" style="width:200px;"></el-input> <span style="color:#F13E1A; margin-left:8px;">*</span>
                            </el-form-item>
                        </div>
                        <div class="popitem">
                            <el-form-item :label="$t('paix')">
                                <el-input v-model="addform.menuSort" style="width:200px;"></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    
                    <div class="popitembox">
                        <div class="popitem">
                            <el-form-item :label="$t('qxurl')">
                                <el-input v-model="addform.component" style="width:200px;"></el-input>
                            </el-form-item>
                        </div>
                        <div class="popitem">
                            <el-form-item :label="$t('qxbs')">
                                <el-input v-model="addform.permission" style="width:200px;"></el-input> <span style="color:#F13E1A; margin-left:8px;">*</span>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="popitembox">
                        <div class="popitem">
                            <el-form-item :label="$t('tup')">
                                <el-input v-model="addform.icon" style="width:200px;"></el-input> <span style="color:#999999; margin-left:8px;"></span>
                            </el-form-item>
                        </div>
                        <div class="popitem">
                        </div>
                    </div>

                </el-form>
            </div>
        </pop-up>

        <pop-up :title="$t('editcd')" width="40%" :isshow="showeditperm" :haveconfirm="true" @close="getclose2" @confirm="getconfirm2">
            <div slot="box">
                <el-form ref="form" :model="editform" label-width="100px">
                    <div class="popitem">
                        <el-form-item :label="$t('type')">
                            <el-radio-group v-model="editform.type">
                            <el-radio :label="0" v-if="editform.pid==0 || editform.pid==null">{{$t('zhucd')}}</el-radio>
                            <el-radio :label="1" v-if="editform.pid!=0 && editform.pid!=null">{{$t('zcd')}}</el-radio>
                            <el-radio :label="2" v-if="editform.pid!=0 && editform.pid!=null">{{$t('gongn')}}</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </div>
                    <div class="popitembox">
                        <div class="popitem">
                            <el-form-item :label="$t('cdmc')">
                                <el-input v-model="editform.title" style="width:220px;"></el-input> <span style="color:#F13E1A; margin-left:8px;">*</span>
                            </el-form-item>
                        </div>
                        <div class="popitem">
                            <el-form-item :label="$t('paix')">
                                <el-input v-model="editform.menuSort" style="width:220px;"></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    
                    <div class="popitembox">
                        <div class="popitem">
                            <el-form-item :label="$t('qxurl')">
                                <el-input v-model="editform.component" style="width:220px;"></el-input>
                            </el-form-item>
                        </div>
                        <div class="popitem">
                            <el-form-item :label="$t('qxbs')">
                                <el-input v-model="editform.permission" style="width:220px;"></el-input> <span style="color:#F13E1A; margin-left:8px;">*</span>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="popitembox">
                        <div class="popitem">
                            <el-form-item :label="$t('tup')">
                                <el-input v-model="editform.icon" style="width:220px;"></el-input> <span style="color:#999999; margin-left:8px;"></span>
                            </el-form-item>
                        </div>
                        <div class="popitem">
                        </div>
                    </div>
                </el-form>
            </div>
        </pop-up>
    </div>
</template>
<script>
import apiurl from "@/api/sys"
import _api from "@/api/index"
export default {
    data(){
        return{
            loading:false,
            showaddperm:false,
            showeditperm:false,
            tableData: [],
            addform:{
                cache:false,
                component:"",
                componentName:null,
                hidden:false,
                icon:"",
                iframe:false,
                menuSort:0,
                path:"",
                permission:"",
                pid:0,
                title:"",
                type:0,
            },
            editform:{
                id:"",
                cache:false,
                component:"",
                componentName:null,
                hidden:false,
                icon:"",
                iframe:false,
                menuSort:0,
                path:"",
                permission:"",
                pid:0,
                title:"",
                type:0,
            },
        }
    },
    created(){
        this.getList()
    },
    methods:{
        load(tree, treeNode, resolve){
            _api.get(apiurl.perm,{pid:tree.id}).then(res=>{
                console.log(res)
                resolve(res.content)
            }).catch(err=>{
                this.$notify.error({
                    title: this.$t('error'),
                    message: err.data.message
                });
            })
        },
        deleteperm(row){
            _api.delete(apiurl.perm,row.id).then(res=>{
                this.$notify({
                    title: this.$t('success'),
                    message: this.$t('delSuccess'),
                    type: 'success'
                });
                this.getList()
            }).catch(err=>{
                this.$notify.error({
                    title: this.$t('error'),
                    message: err.data.message
                });
            })
        },
        getList(){
            this.tableData=[]
            this.loading=true
            _api.get(apiurl.perm,{}).then(res=>{
                this.loading=false
                console.log(res)
                this.tableData=res.content
            })
        },
        openedit(row){
            this.editform.id=row.id
            this.editform.component=row.component
            this.editform.icon=row.icon
            this.editform.menuSort=row.menuSort
            this.editform.permission=row.permission
            this.editform.title=row.title
            this.editform.pid=row.pid
            this.editform.type=row.type*1
            this.showeditperm=true
        },
        getconfirm(){//确认添加
            _api.post(apiurl.perm,this.addform).then(res=>{
                this.$notify({
                    title: this.$t('success'),
                    message: this.$t('addSuccess'),
                    type: 'success'
                });
                this.addform.title=""
                this.addform.component=""
                this.addform.permission=""
                this.addform.icon=""
                this.getclose()
                this.getList()
            }).catch(err=>{
                this.$notify.error({
                    title: this.$t('error'),
                    message: err.data.message
                });
            })
        },
         getconfirm2(){//确认编辑
            _api.put(apiurl.perm,this.editform).then(res=>{
                //this.$msg.success("编辑成功！")
                this.$notify({
                    title: this.$t('success'),
                    message: this.$t('editSuccess'),
                    type: 'success'
                });
                this.getclose2()
                this.getList()
            }).catch(err=>{
                this.$notify.error({
                    title: this.$t('error'),
                    message: err.data.message
                });
            })
        },
        getclose(){
            this.showaddperm=false
        },
        getclose2(){
            this.showeditperm=false
        },
    }
}
</script>
<style>
</style>